import { useAuth } from '~/store/auth';

export default defineNuxtRouteMiddleware((to, from) => {
    const { name } = to;
    if (name === 'index' && useAuth().isAuthorized) {
        return navigateTo(`/home/dashboard`);
    }
    if (name !== 'index' && !useAuth().isAuthorized) {
        return navigateTo(`/?redirect=${encodeURIComponent(to.fullPath)}`);
    }
});
